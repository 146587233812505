export default [
    {
        key: "client_name",
        label: "Client",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "account",
        label: "Account",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "program",
        label: "Program",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "advisor_name",
        label: "Advisor",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "amount",
        label: "Amount",
        sortable: true,
        thClass: "text-center",
        tdClass: "pt-1 text-center",
        formatter: (value) => {
            return `$ ${value}`;
        },
        visible: true,
    },
    {
        key: "transaction_id",
        label: "# Check",
        tdClass: "pt-1",
        visible: true,
    },
    {
        key: "settlement_date",
        label: "Date",
        thClass: "text-center",
        tdClass: "pt-1 text-center",
        visible: true,
    },
    {
        key: "created_at",
        label: "Submit Date/Time/User",
        thClass: "text-center",
        tdClass: "pt-1 text-center",
        visible: true,
    },
]
