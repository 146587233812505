export default [
    {
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "From",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
    },
    {
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "To",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Status",
      model: null,
      options: [],
      reduce: "id",
      selectText: "label",
      cols: 12,
      visible: false,
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Program",
      model: null,
      options: [
        {
          id: 0,
          name: "All",
        },
      ],
      reduce: "id",
      selectText: "name",
      cols: 12,
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Advisor",
      model: null,
      options: [
        {
          id: 0,
          user_name: "All",
        },
      ],
      reduce: "id",
      selectText: "user_name",
      cols: 12,
    },
  ]
  