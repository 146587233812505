<template>
  <div>
    <!-- Tab 3 hace referencia al type_transaction = 3 (Manual Cash) -->
    <automatic-manual-table :tab="3" :fields="fields" :filters="filters" />
  </div>
</template>

<script>
import fields from "@/views/administration/views/register-payment/data/register-payments-other/old.fields";
import filters from "@/views/administration/views/register-payment/data/register-payments-other/old.filters";
import AutomaticManualTable from "@/views/administration/views/register-payment/components/AutomaticManualTable.vue";
export default {
  components: {
    AutomaticManualTable,
  },
  data() {
    return {
      fields,
      filters,
    };
  },
};
</script>

<style>
</style>